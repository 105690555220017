<template>
	<div>
		<div class="pd30">
			<div class="flex alcenter">
				<a-button type="primary" icon="plus" @click="addPartnerAct()">添加运营帐号</a-button>
				
				<div class="flex alcenter ml20">
					<div class="ft14 ftw500 cl-info">运营管理地址：</div>
					<a href="/account#/" target="_blank">点击访问</a>
				</div>
			</div>
			
			<div class="mt20">
				<div class="wxb-table-gray">
					<a-table rowKey="shop_account_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
					 <div class="flex center" slot="username" slot-scope="record">
						 {{record.account.username}}
					 </div>
					 <div class="flex center" slot="mobile" slot-scope="record">
					 	 {{record.account.mobile}}
					 </div>
					 
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="del(record)">
												<i class="iconfont ft14 icondelete"></i>
												<span class="ml10">解绑账号</span>
											</a>
										</a-menu-item>
						
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="editPartnerLoading">
				<edit-partner :shop_account_id="shop_account_id" :visible="editPartnerLoading" @cancel="cancelEditPartner" @ok="okEditPartner"></edit-partner>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../../common/mixin/list.js';
	import editPartner from './modal/partner/editPartner.vue';
	export default {
		components:{
			editPartner,
		},
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				editPartnerLoading:false,
				shop_account_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '姓名',key: 'username',align: 'center',scopedSlots: {customRender: 'username'}},
					{title: '手机号',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		computed:{
			getAccountUrl(){
				return process.env.VUE_APP_ACCOUNT_URL
			}
		},
		created() {
			
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/getShopAccountList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			del(record){
				this.$confirm({
					title:'确认解绑这个账号吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.papi('platform/terminal/delShopAccount',{
								shop_account_id:record.shop_account_id,
							}).then(res=>{
								this.$message.success('解绑成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			addPartnerAct(){
				this.shop_account_id=0;
				this.editPartnerLoading=true;
			},
			// editPartnerAct(record){
			// 	this.shop_account_id=record.shop_account_id;
			// 	this.editPartnerLoading=true;
			// },
			cancelEditPartner(){
				this.editPartnerLoading=false;
			},
			okEditPartner(){
				this.editPartnerLoading=false;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
