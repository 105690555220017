<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input " prefix="手机号" style="width: 230px;" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" style="width: 230px;" prefix="核销员" placeholder="请输入核销员姓名" v-model="form.name" :maxLength="20">
						</a-input>
					</div>
					
					<div class="flex  mt10">
						<a-input class="woyaou-input " type="password" prefix="登陆密码" style="width: 480px;" placeholder="请输入密码" v-model="form.password" :maxLength="20">
						</a-input>
					</div>
					<div class="flex alcenter mt20">
						<div class="ft14 ftw500 cl-info">所属门店</div>
						<div style="width: 200px; margin-left: 10px;">
							<a-select style="width: 200px;" v-model="form.mendian_id">
								<a-select-option :value="0">请选择</a-select-option>
								<a-select-option v-for="(item,index) in mendians" :key="index" :value="item.mendian_id">{{item.name}}</a-select-option>
							</a-select>
						</div>
					</div>	
					
					
					<!-- <div class="flex  mt10">
						<a-input class="woyaou-input" style="width: 230px;" prefix="帐号" placeholder="请输入登录帐号" v-model="form.username" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" type="password" prefix="登录密码" style="width: 230px;" placeholder="请输入登录密码" v-model="form.password" :maxLength="20">
						</a-input>
					</div> -->
					
					<div class="mt20">
						<div class="edit-staff-jurisdiction">
							<div class="flex alcenter">
								<div class="ft14 ftw500 cl-info">权限</div>
								<div class="ft12 ftw400 cl-info ml8">(可多选)</div>
							</div>
							
							
							<!-- <div class="flex alcenter mt20">
								<div class="select-check-box active" v-if="form.adviser_jurisdiction==1" @click="checkAdviser()"> 
									<a-icon type="check" class="cl-w"/>
								</div>
								
								<div class="select-check-box" v-else @click="checkAdviser()">
									
								</div>
								
								<div class="ml8 ft14 ftw500 cl-main">顾问权限</div>
								<div class="ml8 ft12 ftw400 cl-info">(可以对会员进行深度营销)</div>
							</div> -->
							
							<!-- <div class="flex alcenter mt20">
								<div class="select-check-box active" v-if="form.is_cashier_login==1" @click="checkCashierLogin()"> 
									<a-icon type="check" class="cl-w"/>
								</div>
								
								<div class="select-check-box" v-else @click="checkCashierLogin()">
									
								</div>
								
								<div class="ml8 ft14 ftw500 cl-main">是否允许员工登录收银台</div>
							</div> -->
							
							<div class="flex alcenter mt20">
								<div class="select-check-box active" v-if="form.write_off_jurisdiction==1" @click="checkWriteOff()"> 
									<a-icon type="check" class="cl-w"/>
								</div>
								
								<div class="select-check-box" v-else @click="checkWriteOff()">
									
								</div>
								
								<div class="ml8 ft14 ftw500 cl-main">核销权限</div>
								
								<div class="ml8 ft12 ftw400 cl-info">(可核销优惠券、扣除余额、扣除积分)</div>
							</div> 
							
							<div class="mt20">
								<div class="flex ">
									<div class="ft12 ftw500 cl-info" style="width: 150px;">加值权限：</div>
									
									<div class="ml2">
										<div class="flex wrap">
											<div class="flex alcenter mr10 mb10" v-for="(item,index) in jurisdiction">
												<div class="select-check-box active" v-if="form.add_value_jurisdiction.indexOf(item.id)!=-1" @click="removeSelectId(index)">
													<a-icon type="check" class="cl-w"/>
												</div>
												
												<div class="select-check-box " v-else @click="addSelectId(index)">
													
												</div>
												<div class="ml8 ft14 ftw500 cl-main">{{item.name}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			staff_id:{
				type:Number,
				default:0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					name:'',
					mobile:'',
					password:'',
					mendian_id:'',
					is_cashier_login:0,
					// adviser_jurisdiction:0,
					write_off_jurisdiction:0,
					add_value_jurisdiction:[],
				},
				mendians:[],
				jurisdiction:[
					{id:'1',name:'办理会员'},
					{id:'2',name:'余额充值'},
					{id:'3',name:'积分充值'},
					{id:'4',name:'送券'},
					{id:'5',name:'订单管理'},
					{id:'6',name:'囤货订单'},
					{id:'7',name:'代客下单'},
					{id:'8',name:'线下余额补单'},
					{id:'9',name:'相册管理'},
					{id:'10',name:'办理次卡'},
					{id:'11',name:'预约管理'},
				]
				
			}
		},
		computed:{
			getTitle(){
				if(this.staff_id==0){
					return "添加员工账号";
				}else{
					return "编辑员工账号"
				}
			}
		},
		created() {
			this.loaddata();
			this.getMendians();
		},
		methods: {
			getMendians(){
				this.$http.api('admin/getAllMendian').then(res=>{
					this.mendians = res.mendian;
				}).catch(res=>{
					console.log(res);
				});
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showShopStaff',{
					staff_id:this.staff_id,
					// mendian_id:this.mendian_id,
					
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			/**
			 * 选中
			 * @param {Object} index
			 */
			addSelectId(index) {
				if (this.form.add_value_jurisdiction.indexOf(this.jurisdiction[index].id) != -1) {
					this.$message.error("您已经选择过这个了");
				}
				this.form.add_value_jurisdiction.push(this.jurisdiction[index].id);
				console.log(this.form.add_value_jurisdiction);
			},
			
			/**
			 * 取消选择
			 * @param {Object} index
			 */
			removeSelectId(index) {
				let startIndex = this.form.add_value_jurisdiction.indexOf(this.jurisdiction[index].id);
				if (startIndex == -1) {
					this.$message.error("您已经取消过这个了");
				}
				this.form.add_value_jurisdiction.splice(startIndex, 1);
			},
			
			checkWriteOff(){
				if(this.form.write_off_jurisdiction==1){
					this.form.write_off_jurisdiction=0;
				}else{
					this.form.write_off_jurisdiction=1;
				}
			},
			
			checkCashierLogin(){
				if(this.form.is_cashier_login==1){
					this.form.is_cashier_login=0;
				}else{
					this.form.is_cashier_login=1;
				}
			},
			
			// checkAdviser(){
			// 	this.form.adviser_jurisdiction = this.form.adviser_jurisdiction == 1 ? 0 : 1;
			// },
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveShopStaff',{
					staff_id:this.staff_id,
					name:this.form.name,
					mobile:this.form.mobile,
					password:this.form.password,
					is_cashier_login:this.form.is_cashier_login,
					// adviser_jurisdiction:this.form.adviser_jurisdiction,
					write_off_jurisdiction:this.form.write_off_jurisdiction,
					add_value_jurisdiction:this.form.add_value_jurisdiction,
					mendian_id:this.form.mendian_id
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
				
			},
			
		}
	}
</script>

<style>
	.edit-staff-jurisdiction{
		width: 481px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 16px;
	}
	
	.select-check-box{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 2px solid #A6AEC2;
		cursor: pointer;
	}
	
	.select-check-box.active{
		background: #4772FF;
		cursor: pointer;
		border: none;
		line-height: 20px;
		text-align: center;
	}
	.edit-mendian-select {
		position: relative;
	}
	
	.edit-mendian-select .ant-select-selection-selected-value {
		padding-left: 100px;
		float: left;
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		color: #000000;
		text-overflow: ellipsis;
	}
	
	.edit-mendian-select-prefix {
		position: absolute;
		top: 1px;
		height: 30px;
		line-height: 30px;
		margin-left: 11px;
		width: 80px;
	}
</style>
