<template>
	<div>
		<div class="pd30">
			<div class="flex alcenter">
				<a-button type="primary" icon="plus" @click="addStaffAct()">添加员工</a-button>
				
				<div class="flex alcenter ml20">
					<div class="ft14 ftw500 cl-info">员工管理入口：</div>
					<div v-if="miniapp_status > 0" class="ft14 ftw500 cl-main">访问您的微信小程序 > 我的 > 员工管理入口</div>
					<div class="ml8">
						<show-code :status="miniapp_status" path="/pages/client/member/index"></show-code>
					</div>
				</div>
				
			
			</div>
			
			<div class="mt20">
				<div class="wxb-table-gray">
					<a-table rowKey="staff_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
					 
						<template slot="status" slot-scope="text,record">
							<div class="flex center">
								<div v-if="text==1" class="ft14 ftw400 cl-black">在职</div>
								<div v-else class="ft14 ftw400 cl-notice">离职</div>
							</div>
						</template>
						<template slot="mendian" slot-scope="text,record">
							{{text ? text.name : '-'}}
						</template>
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item @click="editStaffAct(record)"  v-if="record.status==1">
											<a class="menu-act" href="javascript:;">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">编辑账号</span>
											</a>
										</a-menu-item>
										<a-menu-item @click="delAct(record)"  v-if="record.status==1">
											<a class="menu-act" href="javascript:;">
												<i class="iconfont ft14 iconapplication_tab01"></i>
												<span class="ml10">设为离职</span>
											</a>
										</a-menu-item>
									
						
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="editStaffLoading">
				<edit-staff :staff_id="staff_id" :visible="editStaffLoading" @cancel="cancelEditStaff" @ok="okEditStaff"></edit-staff>
			</div>
		
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../../common/mixin/list.js';
	import editStaff from './modal/staff/editStaff.vue';
	import showCode from '../../../../components/code/miniqrcode.vue';
	
	export default {
		components:{
			editStaff,
			showCode,
		},
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				editStaffLoading:false,
				showMiniappLoading:false,
				staff_id:0,
				miniapp_status:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [{
						title: '姓名',
						dataIndex: 'name',
						align: 'center',
						ellipsis: true
					},
					{
						title: '手机号',
						dataIndex: 'mobile',
						align: 'center',
						ellipsis: true
					},
					{
						title: '添加时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
					{
						title: '权限',
						dataIndex: 'jurisdiction',
						align: 'center',
						ellipsis: true
					},
					{
						title: '状态',
						dataIndex: 'status',
						align: 'center',
						scopedSlots: {
							customRender: 'status'
						}
					},
					{
						title: '所属门店',
						dataIndex: 'mendian',
						align: 'center',
						scopedSlots: {
							customRender: 'mendian'
						}
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getShopStaffList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					console.log(res);
					this.pagination.total=res.total;
					this.datas=res.list;
					this.miniapp_status = res.miniapp_status;
					
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			
			addStaffAct(){
				this.staff_id=0;
				this.editStaffLoading=true;
			},
			editStaffAct(record){
				this.staff_id=record.staff_id;
				this.editStaffLoading=true;
			},
			cancelEditStaff(){
				this.editStaffLoading=false;
			},
			okEditStaff(){
				this.editStaffLoading=false;
				this.getLists();
			},
			
			
			showMiniappAct(){
				this.showMiniappLoading=true;
			},
			cancelShowMiniapp(){
				this.showMiniappLoading=false;
			},
			delAct(record){
				this.$confirm({
					title:'确认删除这个账号吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/leaveShopStaff',{
								staff_id:record.staff_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			toCashier(){
				this.$router.push('/beautiful/cashier/')
			},
		}
	}
</script>

<style>
	.setting-staff-visit-miniapp{
		border-radius: 4px;
		border: 1px solid #4772FF;
		padding: 4px 8px 4px 8px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	
	.setting-staff-visit-miniapp img{
		width: 12px;
		height: 12px;
		margin-right: 4px;
	}
</style>
